import {useEffect, useState} from "react";
import {cart as cartStore} from "@/store/Cart";
import {autorun} from "mobx";

export default (threshold = 75) => {
  const [subtotal, setSubtotal] = useState(cartStore.subtotalPrice ?? 0);
  const [percentage, setPercentage] = useState(0);
  const [awayFromFreeShipping, setAwayFromFreeShipping] = useState(0);

  useEffect(() => {
    autorun(() => {
      setSubtotal(cartStore.subtotalPrice / 100);
    });
  }, []);

  useEffect(() => {
    setAwayFromFreeShipping(Math.max(threshold - subtotal, 0));
    setPercentage(Math.round(Math.min((100 * subtotal) / threshold, 100)));
  }, [subtotal, threshold]);

  return {
    percentage,
    awayFromFreeShipping,
  };
};
