import PropTypes from "prop-types";

const ProgressBar = ({value}) => (
  <div className="custom-progress-bar overflow-hidden">
    <span style={{width: `${Number.isNaN(value) ? 0 : Number(value)}%`}}></span>
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ProgressBar;
