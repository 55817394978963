import {useEffect, useState} from "react";

export const useSearchParams = () => {
  const [search, setSearch] = useState();

  useEffect(() => {
    const newSearch = new URLSearchParams(location.search);
    setSearch(newSearch);
  }, []);

  return search;
};
