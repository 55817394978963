import {useEffect} from "react";

export default fn => {
  useEffect(() => {
    const closeSelectOnEscape = e => {
      if (e.key === "Escape") {
        fn();
      }
    };
    document.addEventListener("keydown", closeSelectOnEscape);

    return () => {
      document.removeEventListener("keydown", closeSelectOnEscape);
    };
  }, [fn]);
};
