import {PropTypes} from "prop-types";
import {observer} from "mobx-react";
import Modal from "../Modal";
import {formatMoney} from "@shopify/theme-currency";
import {cart as cartStore} from "@/store/Cart";
import useCart from "@/hooks/useCart";
import useFreeShipping from "@/hooks/useFreeShipping";
import {useTranslation} from "react-i18next";
import ProgressBar from "../ProgressBar";
import useJSONSection from "@/hooks/useJSONSection";
import useCustomEventListener from "@/hooks/useCustomEventListener";
import CartItem from "../CartItem";
import CartBanner from "../CartBanner";
import {triggerEvent} from "@/utils/custom-events";
import {cn} from "@/utils/cn";
import Animate from "../Animate";
import {useSearchParams} from "@/hooks/useSearchParams";
import CartUpsell from "../CartUpsell";
import useEscapeKeyPressed from "@/hooks/useEscapeKeyPressed";
import {useEffect} from "react";

const Cart = observer(
  ({
    hideDiscountBanner,
    freeShippingThreshold,
    enableFreeShippingBar = true,
    discountBanner,
    subscriptionPercentage,
    defaultDiscountCode,
    children,
  }) => {
    const {t} = useTranslation();
    const {
      loading,
      closeCart,
      addItem,
      removeItem,
      updateItem,
      changeSubscriptionPlan,
    } = useCart();

    const {percentage, awayFromFreeShipping} = useFreeShipping(
      freeShippingThreshold,
      cartStore.subtotalPrice,
    );

    const hasSubscriptionProduct = cartStore.items.reduce(
      (acc, next) => acc || Boolean(next.selling_plan_allocation),
      false,
    );

    const isCartEmpty = cartStore.items.length === 0;

    // Custom Free Shipping logic
    const progressBarLabel =
      awayFromFreeShipping === 0 || hasSubscriptionProduct
        ? t("cart.free_shipping")
        : t("cart.away_from_free_shipping", {
            money: formatMoney(awayFromFreeShipping * 100),
          });

    const footerShippingLabel =
      percentage === 100 || hasSubscriptionProduct
        ? t("cart.footer.shipping_is_free")
        : t("cart.footer.calc_at_checkout");

    // Custom Upsell logic
    const {data, fetchSection} = useJSONSection("json-cart");
    const upsellProducts = data.upsellProducts;
    const subscriptionPlans = data.subscriptionPlans;

    useEscapeKeyPressed(closeCart);

    useCustomEventListener("cart:reload", fetchSection);

    const search = useSearchParams();

    useEffect(() => {
      if (search) {
        const shouldOpenCart = search.get("open_cart") === "true";
        if (shouldOpenCart) {
          triggerEvent("cart:modal:toggle", {
            open: true,
          });
        }
      }
    }, [search]);

    return (
      <Modal
        id="cart"
        className="fixed right-0 top-0 z-cart h-[100dvh] min-h-[100svh] w-full bg-gray p-0 md:w-[45rem] md:min-w-[42rem]"
        overHeader={true}
      >
        {enableFreeShippingBar && (
          <div
            className={cn(
              "bg-light-gold p-2 text-green transition-opacity",
              loading && "pointer-events-none opacity-70",
            )}
          >
            <span className="block w-full text-center font-dm-sans text-[0.9375rem] font-normal not-italic leading-[140%] tracking-[0.0125rem] text-dark-green">
              {t("cart.free_shipping_title", {
                threshold: freeShippingThreshold,
              })}
            </span>
          </div>
        )}

        {/* Cart Header */}
        <div className="relative text-dark-green">
          <h2 className="py-4 text-center font-dm-sans text-lg font-normal not-italic leading-[120%] tracking-[0.00875rem] md:text-2xl md:leading-[120%]">
            {t("cart.title")}
          </h2>
          <p className="sr-only">{cartStore.itemCount}</p>
          <div id="sr-cart-alert" className="sr-only" aria-live="polite"></div>
          <button
            className="absolute right-4 top-1/2 -translate-y-1/2 md:right-6"
            onClick={closeCart}
            aria-label={t("common.close")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M18.6777 19.0156L9.66206 9.99999M9.66206 9.99999L1 1.33793M9.66206 9.99999L1 18.662M9.66206 9.99999L18.6777 0.984375"
                stroke="black"
                strokeWidth="1.5"
              />
            </svg>
          </button>
        </div>

        {/* Progress Bar */}
        <Animate transitionClass={"fade"} in={!isCartEmpty}>
          <div className="px-6 text-center">
            <ProgressBar value={hasSubscriptionProduct ? 100 : percentage} />
            <span className="block pb-4 pt-2 text-center font-dm-sans text-[14px] font-normal not-italic leading-[130%] tracking-[0.0125rem] text-green md:pb-6 md:pt-3 md:text-xs md:font-normal md:tracking-[0.015rem]">
              {progressBarLabel}
            </span>
          </div>
        </Animate>

        <div className="max-h-[var(--cart-items-height)] overflow-auto pb-[10.5rem] !font-dm-sans md:pb-[226px]">
          {/* Empty Cart */}
          <Animate transitionClass={"fade"} in={isCartEmpty}>
            <h3 className="h2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-green">
              {t("cart.empty")}
            </h3>
          </Animate>

          {/* Cart Body */}
          <div className="flex flex-col gap-2 px-2 md:px-6">
            {/* Cart Items */}

            <Animate.List
              in={cartStore.items.length > 0}
              className={cn(
                "rounded-3xl bg-white p-4 md:p-6 [&>li:last-child>.separator]:hidden ",
                loading && "pointer-events-none opacity-70",
              )}
            >
              {cartStore.items.map((cartItem, i) => {
                const {key, ...props} = cartItem;
                return (
                  <Animate.List.Item
                    transitionClass={"fade"}
                    key={key}
                    id={key}
                  >
                    <CartItem
                      subscriptionPercentage={subscriptionPercentage}
                      key={key ?? i}
                      loading={loading}
                      {...props}
                      onMinus={() => updateItem(cartItem, "decrement")}
                      onPlus={() => updateItem(cartItem, "increment")}
                      onRemove={() => removeItem(cartItem)}
                      activeSellingPlanAllocation={{
                        ...cartItem.selling_plan_allocation,
                      }}
                      activeSubscriptionPlan={{
                        ...cartItem.selling_plan_allocation?.selling_plan,
                      }}
                      subscriptionPlans={
                        (subscriptionPlans &&
                          subscriptionPlans[cartItem.id]?.reduce(
                            (acc, next) => acc.concat(next.selling_plans),
                            [],
                          )) ??
                        []
                      }
                      onSubscriptionChange={selling_plan =>
                        changeSubscriptionPlan(cartItem, selling_plan)
                      }
                    />
                    <div className="separator py-6">
                      <hr className="text-green opacity-40" />
                    </div>
                  </Animate.List.Item>
                );
              })}
            </Animate.List>

            {children}

            <Animate in={!isCartEmpty}>
              <div className="flex flex-col gap-2">
                {/* Upsell */}
                <CartUpsell
                  products={upsellProducts}
                  onItemAdd={id => addItem(id)}
                  loading={loading}
                />

                {/* Banner */}
                {!hideDiscountBanner && (
                    <CartBanner
                      discountBanner={discountBanner}
                      defaultDiscountCode={defaultDiscountCode}
                    />
                  )}
              </div>
            </Animate>
          </div>
        </div>

        {/* Cart Footer */}
        {!isCartEmpty && (
          <div className="absolute bottom-0 mt-10 w-full bg-white p-4 text-green shadow-[0px_0px_15px_0px_rgba(84,129,108,0.40)] md:p-6">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-[1px]">
                <p className="flex justify-between font-dm-sans text-base font-normal not-italic leading-[130%] tracking-[0.0075rem] md:text-[0.875rem] md:font-normal md:leading-[140%] md:tracking-[0.00875rem]">
                  <span>{t("cart.footer.total")}</span>
                  <span>{formatMoney(cartStore.totalPrice)}</span>
                </p>
                <p className="flex justify-between font-dm-sans text-base font-normal not-italic leading-[130%] tracking-[0.0075rem] md:text-[0.875rem] md:font-normal md:leading-[140%] md:tracking-[0.00875rem]">
                  <span>{t("cart.footer.discounts_promotions")}</span>
                  <span>
                    {cartStore.cartDiscountCodes && (
                      <span className="mr-[13px] rounded-full border border-green px-2 text-[12px] leading-[70%] text-green">
                        {cartStore.cartDiscountCodes}
                      </span>
                    )}
                    {cartStore.totalDiscount !== 0 && "-"}
                    {formatMoney(cartStore.totalDiscount)}
                  </span>
                </p>

                <p className="flex justify-between font-dm-sans text-base font-normal not-italic leading-[130%] tracking-[0.015rem] md:text-[0.875rem] md:font-normal md:leading-[140%] md:tracking-[0.00875rem]">
                  <span>{t("cart.footer.estimated_shipping")}</span>
                  <span>{footerShippingLabel}</span>
                </p>
              </div>

              <p className="flex justify-between font-dm-sans text-lg font-normal not-italic leading-[120%] md:text-[1.5rem] md:font-normal">
                <span>{t("cart.footer.subtotal")}</span>
                <span>{formatMoney(cartStore.subtotalPrice)}</span>
              </p>
            </div>
            <div className="py-4 opacity-40">
              <hr />
            </div>
            <a
              className="button button--quaternary block py-2 font-dm-sans text-base font-normal capitalize not-italic leading-[140%] tracking-[0.02rem]"
              href="/checkout"
            >
              {t("cart.checkout")}
            </a>
          </div>
        )}
      </Modal>
    );
  },
);

Cart.propTypes = {
  hideDiscountBanner: PropTypes.bool,
  freeShippingThreshold: PropTypes.number,
  children: PropTypes.node,
  enableFreeShippingBar: PropTypes.bool,
  discountBanner: PropTypes.object,
  defaultDiscountCode: PropTypes.string,
};

export default Cart;
