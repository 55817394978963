import {cn} from "@/utils/cn";
import {PropTypes} from "prop-types";
import useSelect from "@/hooks/useSelect";
import {useId} from "react";
import useEscapeKeyPressed from "@/hooks/useEscapeKeyPressed";
import {useTranslation} from "react-i18next";

const Select = ({value, onValueChange, options, disabled, className}) => {
  const id = useId();
  const {t} = useTranslation();
  const {ref, open, setOpen, setSelectedValue, selectedValue} =
    useSelect(value);

  const handleClick = () => setOpen(open => !open);

  const handleSelectOption = option => {
    setSelectedValue(option);
    onValueChange(option);
  };

  // Close Select on ESC key pressed.
  useEscapeKeyPressed(() => setOpen(false));

  const selectedOption = options?.find(
    option =>
      JSON.stringify(option.value) === JSON.stringify(selectedValue.value),
  );

  return (
    <div className={cn("relative", className)} ref={ref}>
      <button
        role="combobox"
        id={id}
        aria-controls="listbox"
        aria-haspopup="listbox"
        tabIndex="0"
        aria-expanded={open ? "true" : "false"}
        type="button"
        className="h-full w-full disabled:opacity-70"
        onClick={handleClick}
        aria-label="Open select"
        disabled={disabled}
      >
        <label
          htmlFor="product"
          className="flex h-full min-h-[40px] cursor-pointer items-center justify-between gap-2 rounded-full border border-deep-green p-4 py-2 transition-opacity hover:opacity-70 focus:opacity-70"
        >
          <span className="body--regular flex-shrink overflow-hidden text-ellipsis whitespace-nowrap text-deep-green">
            {t("action.select_prefix")} {selectedOption?.label}
          </span>
          <svg
            className={cn(
              "transition-transform duration-200",
              open ? "-rotate-180" : "rotate-0",
            )}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M6.59803 0.886719L6.59803 11.5736L0.568394 5.54401L0 6.1124L7 13.1124L14 6.1124L13.4316 5.54401L7.40198 11.5736L7.40198 0.886719H6.59803Z"
              fill="#104234"
            />
          </svg>
        </label>
      </button>
      <ul
        role="listbox"
        id="listbox"
        className={cn(
          "absolute z-50 max-h-0 w-full overflow-auto rounded-2xl border-deep-green bg-gray transition-all",
          open && "max-h-[234px] border py-2",
        )}
      >
        {options.map(option => {
          return (
            <li
              key={option.label}
              role="option"
              className={cn(
                "px-4 transition-all hover:opacity-70 focus:opacity-70",
                JSON.stringify(option.value) ===
                  JSON.stringify(selectedOption?.value) && "font-bold",
              )}
              onClick={() => handleSelectOption(option)}
              tabIndex={open ? "0" : "-1"}
            >
              <span className="body--regular cursor-pointer text-deep-green">
                {option.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onValueChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

export default Select;
