import {useState, useEffect} from "react";
import {cart as cartStore} from "@/store/Cart";
import useCustomEventListener from "@/hooks/useCustomEventListener";
import {triggerEvent} from "@/utils/custom-events";

export default () => {
  const [loading, setLoading] = useState(false);
  const closeCart = () =>
    triggerEvent("cart:modal:toggle", {
      open: false,
    });
  const openCart = () =>
    triggerEvent("cart:modal:toggle", {
      open: true,
    });

  const itemCount = cartStore.itemCount;

  // A11y - Users get a notification that the cart has indeed changed.
  useEffect(() => {
    const srCartAlert = document.getElementById("sr-cart-alert");
    if (srCartAlert) {
      const newItem = document.createElement("span");
      newItem.textContent = `Product added to cart. You now have ${itemCount} items in your cart.`;
      srCartAlert.appendChild(newItem);
    }
  }, [itemCount]);

  const decorateError =
    fn =>
    async (...args) => {
      try {
        setLoading(true);
        await fn(...args);
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoading(false);
      }
    };

  const reloadCart = decorateError(cartStore.reloadCart);

  const addItem = decorateError(async (id, quantity, selling_plan) => {
    await cartStore.addItem({
      id,
      quantity,
      ...(selling_plan && {
        selling_plan,
      }),
    });
    await cartStore.reloadCart();
  });

  const updateItem = decorateError(async (item, type) => {
    await cartStore.updateItem({
      item,
      type,
    });
    await reloadCart();
  });

  const removeItem = decorateError(async item => {
    await cartStore.removeItem(item);
    await reloadCart();
  });

  const changeSubscriptionPlan = decorateError(
    async (cartItem, selling_plan) => {
      await cartStore.removeItem(cartItem);
      await cartStore.addItem({
        id: cartItem.id,
        quantity: cartItem.quantity,
        selling_plan: selling_plan.id,
      });
      await cartStore.reloadCart();
    },
  );

  useCustomEventListener("cart:modal:toggle", reloadCart);

  return {
    loading,
    closeCart,
    openCart,
    reloadCart,
    changeSubscriptionPlan,
    addItem,
    updateItem,
    removeItem,
  };
};
