import {useEffect, useState, useCallback} from "react";

export default id => {
  const [data, setData] = useState("");

  const fetchSection = useCallback(async () => {
    const result = await fetch(`/?sections=${id}`);
    const response = await result.json();
    setData(response[id]);
  }, [id]);
  useEffect(() => {
    fetchSection();
  }, [id, fetchSection]);

  return {data, fetchSection};
};
