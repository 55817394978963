import {useState, useRef, useEffect} from "react";
import useClickOutside from "./useClickOutside";

export default value => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  useClickOutside(ref, () => setOpen(false));

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setOpen(false);
  }, [selectedValue]);

  return {
    ref,
    open,
    setOpen,
    selectedValue,
    setSelectedValue,
  };
};
