import {PropTypes} from "prop-types";
import {formatMoney} from "@shopify/theme-currency";
import {useState} from "react";
import Select from "../Select";
import {useTranslation} from "react-i18next";
import QuantitySelector from "../QuantitySelector";
import {translateRecharge} from "@/utils/translate-recharge";

const CartItem = ({
  url,
  loading,
  featured_image,
  title,
  quantity,
  onPlus,
  onMinus,
  onRemove,
  subscriptionPlans,
  activeSubscriptionPlan,
  onSubscriptionChange,
  original_line_price,
  line_price,
  activeSellingPlanAllocation,
  subscriptionPercentage = 20,
}) => {
  const {t} = useTranslation();
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const [sellingPlan, setSellingPlan] = useState(activeSubscriptionPlan ?? {});

  const handleSellingPlanChange = option => {
    const sellingPlan = subscriptionPlans.find(
      plan => plan.id === option.value,
    );
    setSellingPlan(sellingPlan);
    onSubscriptionChange && onSubscriptionChange(sellingPlan);
  };

  const selectedSellingPlans = sellingPlan && {
    label: translateRecharge(sellingPlan.name),
    value: sellingPlan.id,
    key: sellingPlan.id,
  };

  const hasSubscriptionSelected =
    Object.keys(activeSubscriptionPlan).length > 0;

  const availableSellingPlans =
    subscriptionPlans.map(plan => ({
      key: plan.id,
      label: translateRecharge(plan.name),
      value: plan.id,
    })) ?? [];

  const shouldShowCompareAtPrice =
    original_line_price > line_price ||
    (activeSellingPlanAllocation?.compare_at_price &&
      quantity * activeSellingPlanAllocation?.compare_at_price > line_price);

  const showSubscriptionPlanSelect =
    onSubscriptionChange &&
    hasSubscriptionSelected &&
    subscriptionPlans.length > 0;

  const shouldShowUpgradeToSub =
    onSubscriptionChange &&
    !hasSubscriptionSelected &&
    subscriptionPlans.length > 0;

  const featuredImage = featured_image;

  return (
    <div>
      <div className="flex gap-4 md:gap-6">
        <div className="h-24 w-24 flex-shrink-0">
          {featuredImage && (
            <a href={url} aria-label={title}>
              <img
                className="rounded-[0.6rem]"
                src={featuredImage?.url}
                alt={featuredImage?.alt}
                width="128"
                height="128"
              />
            </a>
          )}
        </div>

        <div className="flex w-full flex-col justify-between gap-4">
          <div className="flex w-full flex-col justify-between gap-[6px]">
            <div>
              <div className="justify-between md:flex md:max-h-[20px]">
                <a href={url} aria-label={title}>
                  <h3 className="h6 mb-[2px] text-dark-green md:line-clamp-1">
                    {title}
                  </h3>
                </a>
                <div className="mt-[-1px] flex flex-row gap-1 md:flex-col md:gap-0">
                  <span className="text-sm font-normal not-italic leading-[140%] tracking-[0.00875rem] text-dark-green md:text-[1rem] md:tracking-[0.01rem] md:text-green">
                    {!activeSellingPlanAllocation.price
                      ? formatMoney(line_price)
                      : formatMoney(
                          activeSellingPlanAllocation.price * quantity,
                        )}
                  </span>
                  {shouldShowCompareAtPrice && (
                    <span className="text-sm font-normal not-italic leading-[140%] tracking-[0.00875rem] text-dark-green line-through decoration-1 opacity-60 md:text-[1rem] md:tracking-[0.01rem] md:text-green">
                      {!activeSellingPlanAllocation.price
                        ? formatMoney(original_line_price)
                        : formatMoney(
                            activeSellingPlanAllocation.compare_at_price *
                              quantity,
                          )}
                    </span>
                  )}
                </div>
              </div>
              {/* OTP Label */}
              {!showSubscriptionPlanSelect && (
                <span className="mt-[2px] block font-dm-sans text-xs font-normal not-italic leading-[130%] tracking-[0.0075rem] text-dark-green opacity-60 md:flex md:text-sm md:leading-[140%] md:tracking-[0.00875rem] md:opacity-100">
                  {t("cart.otp_label")}
                </span>
              )}
            </div>

            {/* Subscription Plan Selector */}
            {showSubscriptionPlanSelect && (
              <Select
                className="h-[44px] w-full pr-3 sm:min-w-[15.5rem] md:w-fit [&_span]:opacity-60"
                value={selectedSellingPlans}
                onValueChange={handleSellingPlanChange}
                options={availableSellingPlans}
                disabled={loading}
              />
            )}

            <div className="flex justify-between gap-4">
              <div className="flex flex-col justify-between ">
                <QuantitySelector
                  className={"h-[44px]"}
                  value={itemQuantity}
                  onValueChange={setItemQuantity}
                  onPlus={onPlus}
                  onMinus={onMinus}
                />

                {/* Upgrade to Subscription - Desktop */}
                {shouldShowUpgradeToSub && (
                  <button
                    type="button"
                    className="button button--quaternary upgrade-to-subscription-element hidden w-full px-4 font-dm-sans leading-[90%] md:mt-2 md:block"
                    onClick={() => onSubscriptionChange(subscriptionPlans[0])}
                    aria-label={t("cart.upgrade_to_subscription_desktop", {
                      percentage: subscriptionPercentage,
                    })}
                  >
                    <span className="block text-xs font-semibold not-italic leading-[90%]">
                      {t("cart.upgrade_to_subscription_desktop", {
                        percentage: subscriptionPercentage,
                      })}
                    </span>
                  </button>
                )}
              </div>
              <div className="flex gap-4 md:flex-row">
                <button
                  className="tracking-2p text-2xs cursor-pointer self-end justify-self-end py-2 uppercase text-gray underline"
                  onClick={onRemove}
                  disabled={loading}
                  type="button"
                  aria-label={`Remove ${title}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M7.99021 0C6.57665 0 5.41903 1.15819 5.41903 2.57119H0.919027C0.916215 2.57119 0.913402 2.57119 0.910027 2.57119C0.55509 2.57119 0.26709 2.85919 0.26709 3.21413C0.26709 3.56906 0.55509 3.85706 0.910027 3.85706C0.913402 3.85706 0.916215 3.85706 0.91959 3.85706H1.56196V17.3571C1.56196 17.712 1.84996 18 2.2049 18H15.062C15.4169 18 15.7049 17.7126 15.7049 17.3571V3.85706H16.3478C16.3507 3.85706 16.3535 3.85706 16.3568 3.85706C16.7118 3.85706 16.9998 3.56906 16.9998 3.21413C16.9998 2.85919 16.7123 2.57119 16.3568 2.57119C16.3535 2.57119 16.3507 2.57119 16.3473 2.57119H11.8478C11.8478 1.15763 10.6897 0 9.27665 0H7.99021ZM7.99021 1.28587H9.27609C9.99946 1.28587 10.562 1.84781 10.562 2.57175H6.7049C6.7049 1.84837 7.26628 1.28587 7.99021 1.28587ZM2.84728 3.85706H14.4185V16.7141H2.84728V3.85706ZM6.69534 6.41981C6.34434 6.42544 6.06196 6.71119 6.06196 7.06275C6.06196 7.06612 6.06196 7.06894 6.06196 7.07231V7.07175V13.5006C6.06196 13.5034 6.06196 13.5062 6.06196 13.5096C6.06196 13.8645 6.3494 14.1525 6.7049 14.1525C7.0604 14.1525 7.34784 13.8651 7.34784 13.5096C7.34784 13.5062 7.34784 13.5034 7.34784 13.5V7.07175C7.34784 7.06894 7.34784 7.06612 7.34784 7.06331C7.34784 6.70837 7.0604 6.42037 6.7049 6.42037C6.70153 6.42037 6.69815 6.42037 6.69478 6.42037L6.69534 6.41981ZM10.5524 6.41981C10.2014 6.42544 9.91903 6.71119 9.91903 7.06275C9.91903 7.06612 9.91903 7.06894 9.91903 7.07231V7.07175V13.5006C9.91903 13.5034 9.91903 13.5062 9.91903 13.5096C9.91903 13.8645 10.2065 14.1525 10.562 14.1525C10.9175 14.1525 11.2049 13.8651 11.2049 13.5096C11.2049 13.5062 11.2049 13.5034 11.2049 13.5V7.07175C11.2049 7.06894 11.2049 7.06612 11.2049 7.06331C11.2049 6.70837 10.9175 6.42037 10.562 6.42037C10.5586 6.42037 10.5552 6.42037 10.5518 6.42037L10.5524 6.41981Z"
                      fill="#104234"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upgrade to Subscription - Mobile */}
      {shouldShowUpgradeToSub && (
        <button
          type="button"
          className="button button--quaternary upgrade-to-subscription-element mt-4 block h-[25px] w-full px-4 py-1 font-dm-sans md:hidden"
          onClick={() => onSubscriptionChange(subscriptionPlans[0])}
          aria-label={t("cart.upgrade_to_subscription_mobile", {
            percentage: subscriptionPercentage,
          })}
        >
          <span className="mt-[-2px] block text-xs not-italic">
            {t("cart.upgrade_to_subscription_mobile", {
              percentage: subscriptionPercentage,
            })}
          </span>
        </button>
      )}
    </div>
  );
};

CartItem.propTypes = {
  media: PropTypes.array,
  url: PropTypes.string,
  loading: PropTypes.bool,
  image: PropTypes.string,
  featured_image: PropTypes.object,
  title: PropTypes.string,
  price: PropTypes.number,
  final_price: PropTypes.number,
  line_price: PropTypes.number,
  original_line_price: PropTypes.number,
  subscription_percentage: PropTypes.number,
  quantity: PropTypes.number,
  onPlus: PropTypes.func,
  onMinus: PropTypes.func,
  onRemove: PropTypes.func,
  onSubscriptionChange: PropTypes.func,
  subscriptionPlans: PropTypes.array,
  activeSellingPlanAllocation: PropTypes.object,
  activeSubscriptionPlan: PropTypes.object,
  subscriptionPercentage: PropTypes.number,
  discounts: PropTypes.array,
};

export default CartItem;
