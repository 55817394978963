import {useEffect, useState} from "react";
import useShopifySection from "./useShopifySection";
import JSON5 from "json5";

export default id => {
  const [JSONData, setJSONData] = useState({});

  const {data, fetchSection} = useShopifySection(id);

  useEffect(() => {
    if (data) {
      try {
        const content = data
          .match(/(>((.|\s)*)<)/gim)[0]
          .replace(">", "")
          .replace("<", "");

        setJSONData(JSON5.parse(content));
      } catch (e) {
        console.error(e);
      }
    }
  }, [data]);

  return {data: JSONData, fetchSection};
};
