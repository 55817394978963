import {useTranslation} from "react-i18next";
import Animate from "../Animate";
import PropTypes from "prop-types";
import {cn} from "@/utils/cn";
import {formatMoney} from "@shopify/theme-currency";
import { useEffect, useRef, useState } from "react";

const CartUpsell = ({products, loading, onItemAdd}) => {
  const {t} = useTranslation();
  const listRef = useRef(null);
  const [visibleProducts, setVisibleProducts] = useState([]);
  
  useEffect(() => {
    const calculateVisibleProducts = () => {
      if (!listRef.current || !products) return;
      
      const containerWidth = listRef.current.offsetWidth;
      const itemWidth = 147;
      const gap = 8;
      
      const maxItems = Math.floor((containerWidth + gap) / (itemWidth + gap));
      setVisibleProducts(products.slice(0, maxItems));
    };

    calculateVisibleProducts();
  }, [products]);
  
  return (
    <Animate in={products?.length > 0} transitionClass="fade">
      <div
        className={cn(
          "flex flex-col gap-4 rounded-3xl bg-white p-4 transition-opacity md:gap-6 md:p-6",
          loading && "pointer-events-none opacity-70",
        )}
      >
        <h3 className="text-sm font-semibold capitalize not-italic leading-[120%] text-green md:text-[0.875rem]">
          {t("cart.upsell_title")}
        </h3>

        <div ref={listRef} className="flex gap-2 overflow-auto pb-[2px]">
          <Animate.List
            in={products?.length > 0}
            className="flex gap-2"
          >
            {visibleProducts.map((upsellProduct, idx) => (
              <Animate.List.Item
                className="max-w-[9.2rem] flex-shrink-0 overflow-auto"
                key={idx}
              >
                <CartUpsellProduct
                  {...upsellProduct}
                  onAdd={() => onItemAdd(upsellProduct.id)}
                  loading={loading}
                />
              </Animate.List.Item>
            ))}
          </Animate.List>
        </div>
      </div>
    </Animate>
  );
};


CartUpsell.propTypes = {
  products: PropTypes.array,
  onItemAdd: PropTypes.func,
  loading: PropTypes.bool,
};

const CartUpsellProduct = ({
  featured_image,
  title,
  price,
  compare_at_price,
  onAdd,
  isLoading,
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex h-full flex-shrink-0 flex-col justify-between gap-2">
      <div className="upsell-image-wrap relative">
        <img className="upsell-image" src={featured_image} alt={title} />
        <button className="basic-button hidden">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x=".199"
              y="-.004"
              width="28.8"
              height="28.8"
              rx="14.4"
              fill="#fff"
            />
            <path
              d="m20.433 19.36-2.22-2.221c1.699-2.117 1.535-5.247-.403-7.17a5.366 5.366 0 0 0-3.815-1.58 5.392 5.392 0 0 0-3.816 1.58 5.366 5.366 0 0 0-1.58 3.816c0 1.446.566 2.787 1.58 3.816a5.366 5.366 0 0 0 3.816 1.58 5.416 5.416 0 0 0 3.368-1.178l2.22 2.22c.12.12.269.18.418.18.164 0 .313-.06.417-.18a.626.626 0 0 0 .015-.864Zm-2.235-5.59a4.17 4.17 0 0 1-4.189 4.188 4.17 4.17 0 0 1-4.188-4.188 4.17 4.17 0 0 1 4.188-4.188 4.17 4.17 0 0 1 4.189 4.188Z"
              fill="#2D6252"
            />
          </svg>
        </button>
      </div>
      <div>
        <p className="font-dm-sans text-sm font-normal not-italic leading-[140%] tracking-[0.00875rem] text-dark-green">
          {title}
        </p>
        <p className="flex font-dm-sans text-[0.75rem] font-normal not-italic leading-[140%] tracking-[0.0075rem] text-dark-green">
          {formatMoney(price)}
          {compare_at_price && (
            <span className="strike">{formatMoney(compare_at_price)}</span>
          )}
        </p>
      </div>
      <button
        onClick={onAdd}
        className="button button--secondary font-dm-sans text-base font-normal not-italic leading-[140%] tracking-[0.01rem]"
      >
        {isLoading ? t("cart.adding") : t("cart.add")}
      </button>
    </div>
  );
};

CartUpsellProduct.propTypes = {
  featured_image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  compare_at_price: PropTypes.number,
  onAdd: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CartUpsell;
