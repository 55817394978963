import {PropTypes} from "prop-types";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {cart as cartStore} from "../../store/Cart";
import {observer} from "mobx-react";

const CartBanner = observer(({discountBanner, defaultDiscountCode}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);

  const [discountCode, setDiscountCode] = useState(defaultDiscountCode);

  const handleFormSubmit = async e => {
    e.preventDefault();

    try {
      await cartStore.applyDiscountLineItem({
        discountCode,
      });
      await cartStore.reloadCart();
    } catch (e) {
      console.error(e.message);
    }

    setOpen(false);
  };

  return (
    <div className="relative flex h-48 flex-col gap-4 overflow-hidden rounded-3xl bg-green p-4 transition-opacity md:gap-6">
      {discountBanner && (
        <img
          className="image object-cover"
          src={discountBanner.src}
          alt={discountBanner.alt}
          srcSet={discountBanner.srcset}
          sizes={discountBanner.sizes}
          loading="lazy"
        />
      )}

      <h3 className="relative font-dm-sans text-[1.125rem] font-normal not-italic leading-[120%] text-white md:text-[1.5rem]">
        {t("cart.discount_banner_title")}
      </h3>

      <SwitchTransition>
        <CSSTransition
          nodeRef={formRef}
          timeout={400}
          mountOnEnter
          unmountOnExit
          classNames="slide-right"
          key={open ? "open" : "closed"}
        >
          {!open ? (
            <button
              type={"button"}
              className="button button--primary absolute bottom-6 !w-[calc(100%-2rem)] flex-shrink-0 py-4 text-center !font-dm-sans text-base font-normal capitalize not-italic leading-[70%] tracking-[0.02rem] md:bottom-4 md:!w-fit md:px-8"
              onClick={() => setOpen(true)}
            >
              {t("cart.apply_discount")}
            </button>
          ) : (
            <form
              ref={formRef}
              onSubmit={handleFormSubmit}
              className="absolute bottom-6 flex w-[calc(100%-3rem)] gap-2"
            >
              <input
                className="block w-full rounded-full border-b border-white indent-4"
                value={discountCode}
                onChange={e => setDiscountCode(e.currentTarget.value)}
                name="discountCode"
                autoComplete="off"
                required
              />
              <button className="button  button--primary !w-fit flex-shrink-0 py-4 text-center font-dm-sans text-base not-italic leading-[70%] tracking-[0.02rem]">
                {t("cart.submit_discount_code")}
              </button>
            </form>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
});

CartBanner.propTypes = {
  discountBanner: PropTypes.object,
};

export default CartBanner;
